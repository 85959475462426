<template>
  <div class="nav">
    <div class="nav-menutext"></div>
    <div class="nav-items">
      <ul class="nav-list">
        <li>
          <router-link :to="'/settings'"  :class="$route.path === '/settings' ? 'active' : null" class="nav-button">
            <img src="https://gcdn.bionluk.com/site/c/ic-settings.svg" class="nav-button-img">
            Genel Ayarlar
          </router-link>
        </li>

        <li v-if="!user.seller.isSeller">
          <router-link :to="'/settings/business'" active-class="active" class="nav-button">
            <img src="https://gcdn.bionluk.com/site/c/buyer_info_form_dark.svg" class="nav-button-img">
            İşin Hakkında
          </router-link>
        </li>

        <li v-if="user.seller.isSeller || user.info.lastOrderId">
          <router-link :to="'/settings/finance'" active-class="active" class="nav-button">
            <img src="https://gcdn.bionluk.com/site/c/ic-finance.svg" class="nav-button-img">
            Banka ve Kart Bilgileri
          </router-link>
        </li>

        <li>
          <router-link :to="'/settings/security'" active-class="active" class="nav-button">
            <img src="https://gcdn.bionluk.com/site/c/ic-lock.svg" class="nav-button-img">
            Güvenlik
          </router-link>
        </li>

        <li>
          <router-link :to="'/settings/notifications'" active-class="active" class="nav-button">
            <img src="https://gcdn.bionluk.com/site/c/ic-notifications.svg" class="nav-button-img">
            Bildirim Ayarları
          </router-link>
        </li>
      </ul>
    </div>
    <div class="nav-left-footer">
      <footer-nav></footer-nav>
    </div>
  </div>
</template>

<script>
  import FooterNav from "../../components/shared/navFooter"

  export default {
    name: "src-pages-body-settings-navComponent",
    components: { FooterNav }
  }
</script>

<style scoped lang="scss">

  .nav-button {
    display: flex;
    align-items: center;
  }
  .active {
    .nav-button-img {
      opacity:1;
    }
  }

  .nav-button-img {
    height: 18px;
    width: 28px;
    opacity: 0.4;
  }

</style>
