<template>
  <div class="settings-container">
    <setting-nav />
    <div class="content-container">
      <div class="section-header-body" style="margin-top: 0" v-if="user.seller.isSeller">
        <h3 class="section-header">Banka <span>Bilgileri</span></h3>
        <div class="edit-area" @click="editAreaClick(1)" v-if="editableSectionId !== 1 && user.seller.isRegistered">
          <img src="https://gcdn.bionluk.com/site/icon/aws/icon_edit_kalem.svg" alt="kalem" width="14" height="14">
          <p class="edit-text">Düzenle</p>
        </div>
        <div style="display: flex; align-items: center" v-else-if="editableSectionId === 1">
          <p class="use-profile-info-text">Profil Bilgilerimi Kullan</p>
          <custom-switch @change="useProfileInfo"/>
        </div>
      </div>
      <div class="seller-type-body" v-if="editableSectionId === 1 && user.seller.isSeller">
        <div :data-balloon="dataBalloonText"
             data-balloon-pos="up"
             :data-balloon-visible="openBankTooltip"
             @mouseover="openBankTooltip = false"
             data-balloon-length="medium" class="seller-type-text-body">
          <p class="type-header" :style="openBankTooltip ? 'color: #2d3640' : ''">Hesap Türü</p>
          <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="hint-skill-icon"/>

        </div>

        <button class="type-radio-body" v-for="(type, index) in sellerTypeArr" :key="index" @click="selectSellerType(type.value)" :disabled="user.seller.isRegistered && tempSellerInfo.type !== type.value && !isEditable">
          <div class="type-radio-body-button" :style="tempSellerInfo.type === type.value ? 'background-color: #00a575; border: none;' : ''">
            <span class="selected-radio-mask" v-if="tempSellerInfo.type === type.value"></span>
          </div>
          <p class="type-radio-text" :style="tempSellerInfo.type === type.value ? 'font-weight: 500;' : ''">{{type.name}}</p>
        </button>
      </div>
      <section class="section" v-if="user.seller.isSeller">

        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[0]">
            <loader/>
          </div>

          <!--not editable area-->
          <div class="only-text-body" v-if="editableSectionId !== 1 && !user.seller.isRegistered">
            <p class="no-card-text"><span>Hey Freelancer! </span> 👋 Bionluk üzerinden elde ettiğin kazancı sana gönderebilmemiz için banka hesap bilgilerini eklemen gerekiyor.</p>
            <button class="add-dash-button" style="margin-left: 32px;" @click="editAreaClick(1)">
              <img src="https://gcdn.bionluk.com/site/c/ic-add-or-plus.svg" onload="SVGInject(this)" class="add-icon"/>
              Ekle
            </button>
          </div>

          <!--not editable area-->
          <div class="bank-info" v-else-if="editableSectionId !== 1 && user.seller.isRegistered">
            <div class="contact-item">
              <label class="contact-label left">Hesap Türü</label>
              <p class="contact-text right">{{sellerInfo.type ? sellerTypeArr.find(({ value }) => value === sellerInfo.type).name : ''}}</p>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
            <div class="contact-item">
              <label class="contact-label left">Ad & Soyad</label>
              <p class="contact-text right" style="text-transform: capitalize;">{{sellerInfo.firstname}} {{sellerInfo.lastname}}</p>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
            <div class="contact-item">
              <label class="contact-label left">T.C. Kimlik No</label>
              <p class="contact-text right">{{sellerInfo.identification_id}}</p>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
            <div class="contact-item">
              <label class="contact-label left">Cep Telefonu</label>
              <p class="contact-text right">{{sellerInfo.gsm}}</p>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
            <div class="contact-item">
              <label class="contact-label left">IBAN</label>
              <p class="contact-text right" style="font-weight: 800;">{{sellerInfo.IBAN}}</p>
            </div>
            <template v-if="sellerInfo.type === 'bireysel'">
              <div v-if="!requestWaiter[0]" class="contact-item">
                <label class="contact-label left"></label>
                <div class="contact-text right">
                  <div class="finance-info for-seller" v-if="!sellerInfo.isAkbankIban">
                    <div style="margin-left: 20px">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
                    </div>
                    <div style="margin-left: 15px">
                      <div class="description">
                        <p class="disapproval-row">Sen de Akbank'a geç, komisyon indirimi ve maaş müşterisi avantajlarından yararlanlan! Kampanya detayları için <a class="link" href="https://bionluk.com/destek/akbankli-freelancerlara-ozel-kampanya-103" target="_blank">tıkla!</a> 👀</p>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="!sellerInfo.isAkbankUser" class="finance-info for-seller" style="height: 180px;">
                    <div style="margin-left: 20px">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
                    </div>
                    <div style="margin-left: 15px">
                      <div class="description">
                        <p class="disapproval-row">
                          <div class="description">
                          <p class="disapproval-row">Hey! Akbanklı freelancerlarımıza sağladığımız komisyon indirimi ve maaş müşterisi avantajlarından yararlanlanmak ister misin? 👀</p>
                          <p><custom-button @click="showAkbankModal()" style="margin-top: 20px" class="border" button-style="border-radius:4px; padding-bottom:2px; height:40px; width:300px">Kampanyaya Katılmak İstiyorum</custom-button></p>
                        </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="finance-info for-seller" v-else-if="sellerInfo.isAkbankUser" style="height: 120px">
                    <div style="margin-left: 20px">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
                    </div>
                    <div style="margin-left: 15px">
                      <div class="description">
                        <p class="disapproval-row">Tebrikler! <a class="link" href="https://bionluk.com/destek/akbankli-freelancerlara-ozel-kampanya-103" target="_blank">Akbank kampanyası</a> listemizdesin! Komisyon indiriminden şu an yararlanıyorsun. Akbanklı freelancerlar'ın ilgili avantajlarının tanımlanması ise 7 iş günü içinde tamamlanıyor.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
            <div class="contact-item" v-if="sellerInfo.type !== 'bireysel'">
              <label class="contact-label left">Vergi Dairesi</label>
              <p class="contact-text right">{{sellerInfo.tax_office}}</p>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" v-if="sellerInfo.type !== 'bireysel'"></div>
            <div class="contact-item" v-if="sellerInfo.type !== 'bireysel'">
              <label class="contact-label left">Vergi Numarası</label>
              <p class="contact-text right">{{sellerInfo.tax_id}}</p>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" v-if="sellerInfo.type !== 'bireysel'"></div>
            <div class="contact-item" v-if="sellerInfo.type !== 'bireysel'">
              <label class="contact-label left">Firma Resmi Ünvanı</label>
              <p class="contact-text right">{{sellerInfo.company_title}}</p>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" v-if="sellerInfo.type !== 'bireysel'"></div>
            <div class="contact-item">
              <label class="contact-label left">Adres Bilgisi</label>
              <p class="contact-text right">{{sellerInfo.address}}</p>
            </div>
          </div>

          <!--editable area-->
          <div class="bank-info" v-else-if="editableSectionId === 1" style="padding-left: 44px; padding-right: 43px;">
            <div style="display: flex; margin-bottom: 57px;">
              <custom-input label="Ad"
                            inputType="text"
                            input-style="text-transform: capitalize;"
                            v-model="tempSellerInfo.firstname"
                            :valid="tempSellerInfo.firstname !== sellerInfo.firstname ? true : null"
                            style="width: 166px;"/>
              <custom-input label="Soyad"
                            inputType="text"
                            input-style="text-transform: capitalize;"
                            v-model="tempSellerInfo.lastname"
                            :valid="tempSellerInfo.lastname !== sellerInfo.lastname ? true : null"
                            style="margin-left: 11px; width: 166px;"/>
              <custom-input label="T.C. Kimlik No"
                            inputType="number"
                            maxlength="11"
                            v-model="tempSellerInfo.identification_id"
                            :valid="tempSellerInfo.identification_id !== sellerInfo.identification_id ? true : null"
                            style="margin-left: 40px; width: 343px;"/>
            </div>
            <div style="display: flex; margin-bottom: 57px;">
              <custom-input label="Cep Numarası"
                            maxlength="25"
                            v-model="tempSellerInfo.gsm"
                            :valid="tempSellerInfo.gsm !== sellerInfo.gsm ? true : null"
                            style="width: 343px;"/>
              <div style="position: relative;">
                <custom-input label="IBAN Bilgisi"
                              v-model="tempSellerInfo.IBAN"
                              @focus="openIbanTooltip = false"
                              :valid="tempSellerInfo.IBAN !== sellerInfo.IBAN ? true : null"
                              style="margin-left: 40px; width: 343px;"/>
                <div data-balloon="Bu alanda sana ait bir IBAN bilgisi eklemen gerekiyor. Aksi halde ödeme geri döner. Sadece Türkiye'de faaliyet gösteren bir banka kullanabilirsin. "
                     data-balloon-pos="up"
                     :data-balloon-visible="openIbanTooltip"
                     data-balloon-length="medium"  style="position: absolute; right: -20px; top: 0; bottom: 0; margin: auto; width: 16px; height: 16px;">
                  <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg"/>
                </div>
              </div>


            </div>
            <div style="display: flex; margin-bottom: 57px;" v-if="tempSellerInfo.type !== 'bireysel'">
              <custom-input label="Vergi Dairesi"
                            inputType="text"
                            v-model="tempSellerInfo.tax_office"
                            :valid="tempSellerInfo.tax_office !== sellerInfo.tax_office ? true : null"
                            style="width: 166px;"/>
              <custom-input label="Vergi Numarası"
                            inputType="number"
                            maxlength="10"
                            v-model="tempSellerInfo.tax_id"
                            :valid="tempSellerInfo.tax_id !== sellerInfo.tax_id ? true : null"
                            style="margin-left: 11px; width: 166px;"/>
              <div style="position:relative;">
                <custom-input label="Firma Resmi Ünvanı"
                              @focus="openLegalCompanyTitleTooltip = false"
                              v-model="tempSellerInfo.company_title"
                              :valid="tempSellerInfo.company_title !== sellerInfo.company_title ? true : null"
                              style="margin-left: 40px; width: 343px;"/>
                <div data-balloon="Banka hesap bilgilerindeki resmi hesap ismini yazman gerekiyor. Aksi halde ödeme geri döner."
                     data-balloon-pos="up"
                     :data-balloon-visible="openLegalCompanyTitleTooltip"
                     data-balloon-length="medium"  style="position: absolute; right: -20px; top: 0; bottom: 0; margin: auto; width: 16px; height: 16px;">
                  <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg"/>
                </div>
              </div>
            </div>

            <custom-input label="Adres Bilgisi"
                          inputType="textArea"
                          v-model="tempSellerInfo.address"
                          :valid="tempSellerInfo.address !== sellerInfo.address ? true : null"
                          style="width: 726px; height: 116px;"/>

            <div  style="display: flex; justify-content: flex-end; margin-top: 37px;">
              <custom-input label="İlçe"
                            inputType="text"
                            input-style="text-transform: capitalize;"
                            v-model="tempSellerInfo.town"
                            :valid="tempSellerInfo.town !== sellerInfo.town ? true : null"
                            style="width: 186px;"/>

              <custom-input label="Şehir"
                            inputType="text"
                            input-style="text-transform: capitalize;"
                            v-model="tempSellerInfo.city"
                            :valid="tempSellerInfo.city !== sellerInfo.city ? true : null"
                            style="width: 196px; margin-left: 30px;"/>
            </div>


          </div>

          <div class="separator" style="margin-top: 50px;" v-if="editableSectionId === 1"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 1">
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableCancelButton(1)" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="disableSaveButton(1)" @click="saveButton(1)">Kaydet</custom-button>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="section-header-body" :style="!user.seller.isSeller ? 'margin-top: 0px;' : ''">
          <h3 class="section-header">Kayıtlı <span>Kartların</span></h3>
        </div>
        <div class="section-body" :style="!cards.length ? '' : 'padding: 0;'">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[1]">
            <loader/>
          </div>

          <!--not editable area-->
          <div class="only-text-body" >
            <p class="no-card-text">Bionluk’a kartlarını bir defa tanımlarsın, her siparişte kart bilgisi girmekten kurtulursun. Bionluk’ta kart bilgilerin <span>256-bit SSL sertifikası</span> ile şifrelenmekte ve en güvenli şekilde saklanmaktadır.</p>
            <button v-if="false" class="add-dash-button" style="margin-left: 32px;" @click="addCard()">
              <img src="https://gcdn.bionluk.com/site/c/ic-add-or-plus.svg" onload="SVGInject(this)" class="add-icon"/>
              Ekle
            </button>
          </div>

          <!--editable area-->
          <div class="card-info-section"  v-if="cards.length">
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="index in cardRange" :key="index" class="checkout-cards-inner">

                <div v-if="cards[index]" class="cards">
                  <div style="width: 100%; height: 54px; display: flex; justify-content: center; align-items: center">
                    <img :src="cards[index].card_image_url" style="max-width:100px;max-height: 40px"/>
                  </div>
                  <p class="card-name">{{cards[index].card_bank_name}}</p>
                  <p class="card-number">{{cards[index].card_no}}</p>
                  <div class="delete-card" @click="assignForgetCardAlertId($event, cards[index].card_id)">
                    <img src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" width="9" height="12" class="delete-card-img"/>
                    <p class="delete-card-text">Kartı Unut</p>
                  </div>
                  <div class="forget-card-alert" v-if="forgetCardAlertID === cards[index].card_id">
                    <p class="forget-card-alert-text">Emin misin?</p>
                    <div style="display: flex;">
                      <div class="forget-card-alert-button" @click="deleteCard($event, cards[index].card_id)">Evet</div>
                      <div class="forget-card-alert-button" @click="assignForgetCardAlertId($event, null)" style="margin-left: 10px">Hayır</div>
                    </div>
                  </div>
                </div>

                <div v-if="cards[index + 1]" class="cards">
                  <div style="width: 100%; height: 54px; display: flex; justify-content: center; align-items: center">
                    <img :src="cards[index + 1].card_image_url" style="max-width:100px;max-height: 40px"/>
                  </div>
                  <p class="card-name">{{cards[index + 1].card_bank_name}}</p>
                  <p class="card-number">{{cards[index + 1].card_no}}</p>
                  <div class="delete-card" @click="assignForgetCardAlertId($event, cards[index + 1].card_id)">
                    <img src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" width="9" height="12" class="delete-card-img"/>
                    <p class="delete-card-text">Kartı Unut</p>
                  </div>
                  <div class="forget-card-alert" v-if="forgetCardAlertID === cards[index + 1].card_id">
                    <p class="forget-card-alert-text">Emin misin?</p>
                    <div style="display: flex;">
                      <div class="forget-card-alert-button" @click="deleteCard($event, cards[index + 1].card_id)">Evet</div>
                      <div class="forget-card-alert-button" @click="assignForgetCardAlertId($event, null)" style="margin-left: 10px">Hayır</div>
                    </div>
                  </div>
                </div>

                <div v-if="cards[index + 2]" class="cards">
                  <div style="width: 100%; height: 54px; display: flex; justify-content: center; align-items: center">
                    <img :src="cards[index + 2].card_image_url" style="max-width:100px;max-height: 40px"/>
                  </div>
                  <p class="card-name">{{cards[index + 2].card_bank_name}}</p>
                  <p class="card-number">{{cards[index + 2].card_no}}</p>
                  <div class="delete-card" @click="assignForgetCardAlertId($event, cards[index + 2].card_id)">
                    <img src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" width="9" height="12" class="delete-card-img"/>
                    <p class="delete-card-text">Kartı Unut</p>
                  </div>
                  <div class="forget-card-alert" v-if="forgetCardAlertID === cards[index + 2].card_id">
                    <p class="forget-card-alert-text">Emin misin?</p>
                    <div style="display: flex;">
                      <div class="forget-card-alert-button" @click="deleteCard($event, cards[index + 2].card_id)">Evet</div>
                      <div class="forget-card-alert-button" @click="assignForgetCardAlertId($event, null)" style="margin-left: 10px">Hayır</div>
                    </div>
                  </div>
                </div>

              </swiper-slide>
              <div v-show="cards.length > 3" class="swiper-button-prev" slot="button-prev"></div>
              <div v-show="cards.length > 3" class="swiper-button-next" slot="button-next"></div>
            </swiper>

          </div>
          <div class="mt-4">
            Kayıtlı kart bulunamadı.
          </div>
        </div>
      </section>

    </div>
    <div class="grey-background"></div>
  </div>
</template>

<script>
  import SettingNav from "../navComponent";
  import CustomInput from "../../../components/CustomInput";
  import CustomSwitch from "../../../components/CustomSwitch";
  import CustomButton from "../../../components/CustomButton";
  import CustomSelect from "../../../components/CustomSelect";
  import helpBox from "../../../components/HelpBox";
  import range from 'lodash/range'

  export default {
    name: "src-pages-body-settings-finance-v1",
    components: {
      SettingNav,
      CustomInput,
      CustomButton,
      CustomSelect,
      CustomSwitch,
      helpBox
    },
    data() {
      return {
        editableSectionId: null,
        isEditable: false,
        cards: [],
        requestWaiter: [
          true,
          true
        ],
        sellerInfo: {
          gsm: '',
          lastname: '',
          firstname: '',
          identification_id: '',
          address: '',
          IBAN: '',
          company_title: '',
          tax_id: '',
          tax_office: '',
          isAkbankIban:null,
          isAkbankUser:null
        },
        tempSellerInfo: {
          gsm: '',
          lastname: '',
          firstname: '',
          identification_id: '',
          address: '',
          IBAN: '',
          company_title: '',
          tax_id: '',
          tax_office: '',
          town:'',
          city:'',
          isAkbankIban:null,
          isAkbankUser:null
        },

        openBankTooltip: true,
        dataBalloonText: '',
        openIbanTooltip: true,
        openLegalCompanyTitleTooltip:true,
        disableCardDelete: false,
        forgetCardAlertID: null,
        swiperOption: {
          notNextTick: true,
          direction: 'horizontal',
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          slidesPerView: 1,
          observeParents: false,
          speed: 500
        },

        sellerTypeArr: [
          {
            name: 'Bireysel Hesap',
            value: 'bireysel'
          },
          {
            name: 'Şahıs Şirketi',
            value: 'sahissirket'
          },
          {
            name: 'Limited veya Anonim Şirket',
            value: 'ltdas'
          }
        ]
      }
    },

    methods: {

      showAkbankModal(){
        const modalObj = {
          title: 'Akbank Freelancer Kampanyası',
          bodyText:
            `Hesap Sahibi: <span style="font-weight: bold">${this.sellerInfo.firstname} ${this.sellerInfo.lastname} </span><br>
              IBAN: <span style="font-weight: bold">${this.sellerInfo.IBAN}</span><br><br>
               <span style="color: #3a4653; font-size: 14px;">Kampanyaya katılımını gerçekleştirdikten sonra, komisyon indiriminden yararlandığın hakedişlerin tamamını yukarıda belirtilen IBAN hesabına çekmeden IBAN bilgini değiştiremezsin. <br><br>Akbanklı freelancerlar her pazartesi belirleniyor ve ilgili avantajların tanımlanması 7 iş gününü bulabiliyor. Akbank hesabını tanımladıktan sonra Bionluk'tan alacağın 2. ödemeden itibaren avantajlarını kullanabileceksin. <br>Kampanya 31 Aralık 2024 tarihine kadar devam edecek. 🥳</span>`,
          actionButton: {
            show: true,
            title: 'Katıl',
            url: '',
            cancelButtonTitle: 'İptal',
            eventName: 'updateAkbankEvent'
          }

        };
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WARNING_MODAL, info: {...modalObj}});
      },

      updateAkbankUser(){

        this.api.content.userAkbank()
          .then(({data}) => {
            let result = data;
            if(result.success) {
              this.$toasted.global.infoToast({description: result.message});
              location.reload();
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }

          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});

          });
      },

      setInitialValues() {
        if (this.user.seller.isRegestered) {
          this.dataBalloonText = "Eğer Hesap Türünü değiştirmek istiyorsan bizimle destek@bionluk.com üzerinden iletişime geçmen gerekiyor. 😉";
        } else {
          this.dataBalloonText = "Fatura işlemleri için gerekli olan Hesap Türü’nü daha sonra değiştirmek istersen bizimle destek merkezi üzerinden iletişime geçmen gerekiyor. 😉";
        }
        this.getUserCards();
      },

      initializeSeller() {
        this.getSeller();
      },

      getUserCards() {
        this.api.user.getUserCards()
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.cards = result.data.cards || [];
            }
            this.handleRequestWaiter(2);
          })
          .catch(err => {
            this.handleRequestWaiter(2);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      getSeller() {
        this.api.seller.getSeller()
          .then(({data}) => {
            let result = data;
            if (result.success) {
              const seller = result.data.seller;
              let str = seller.info.gsm;
              if (str) {
                const regStr = str.replace(/ /g, "");
                let newval = '';
                regStr.split('').forEach((reg, i) => {
                  if (i > 0 && i < 7 && i % 3 === 0) {
                    newval = newval.concat(' ');
                  } else if (i > 7 && i % 2 === 0) {
                    newval = newval.concat(' ');
                  }
                  newval = newval.concat(regStr[i]);
                  str = newval;
                })
              }
              seller.info.gsm = str;
              seller.info.type = seller.type;
              seller.info.isAkbankIban = seller.info.is_akbank_iban;
              seller.info.isAkbankUser = seller.info.is_akbank_user;
              this.sellerInfo = {...seller.info};
              this.tempSellerInfo = {...seller.info};
              this.isEditable = result.data.isEditable
              if(this.isEditable){
                this.dataBalloonText = "Hesap Türünü bir defaya mahsus değiştirebilirsin, sonraki değişiklikler için bizimle destek merkezi üzerinden iletişime geçmen gerekiyor. 😉";
              }
            } else {
              this.$router.push(result.data.redirect_url);
            }
            this.handleRequestWaiter(1);

          })
          .catch(err => {
            this.handleRequestWaiter(1);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      useProfileInfo(e) {
        const tempSellerInfo = {...this.tempSellerInfo};
        if (e) {
          tempSellerInfo.firstname = this.user.firstname;
          tempSellerInfo.lastname = this.user.lastname;
          let str = this.user.info.gsm;
          if (str) {
            const regStr = str.replace(/ /g, "");
            let newval = '';
            regStr.split('').forEach((reg,i) => {
              if (i > 0 && i < 7 && i % 3 === 0) {
                newval = newval.concat(' ');
              } else if (i > 7 && i % 2 === 0) {
                newval = newval.concat(' ');
              }
              newval = newval.concat(regStr[i]);
              str = newval;
            })
            tempSellerInfo.gsm = str;
          }

        } else {
          tempSellerInfo.firstname = this.sellerInfo.firstname;
          tempSellerInfo.lastname = this.sellerInfo.lastname;
          tempSellerInfo.gsm = this.sellerInfo.gsm;
        }

        this.tempSellerInfo = {...tempSellerInfo};
      },

      editAreaClick(section = null) {

        if(section === 1 && this.user.info.gsm_is_activated === false){
          let info = {
            title:'Onaylı Cep Telefonu',
            bodyText:'Banka bilgilerini girebilmen için Cep Telefonu Numaranı onaylatman gerekiyor.',
            actionButton:{
              show:true,
              title:'Numaramı Onayla',
              url: this.user.info.gsm ? '/settings?section=2' : '/settings?section=2&open=1',
              cancelButtonTitle:null
            }
          };
          this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WARNING_MODAL, info: info});
        } else {
          this.editableSectionId = section;
        }
      },

      saveButton(sectionId) {
        this.handleRequestWaiter(sectionId, true);
        if (sectionId === 1) this.iyziUpdate(sectionId);
      },

      cancelButton() {
        this.tempSellerInfo = {...this.sellerInfo};
        this.editableSectionId = null;
      },

      addCard() {
        return false;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ADD_NEW_CARD});
      },

      deleteCard(event, cardId) {
        this.handleRequestWaiter(2, true);
        event.stopPropagation();
        if (this.disableCardDelete) {
          return false;
        }
        this.disableCardDelete = true;
        this.api.orders.forgetCard(cardId, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.cards = this.cards.filter(({ card_id }) => card_id === cardId)

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.disableCardDelete = false;
            this.handleRequestWaiter(2);
          })
          .catch(err => {
            this.disableCardDelete = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      assignForgetCardAlertId(event, cardId) {
        event.stopPropagation();
        this.forgetCardAlertID = cardId;
      },

      disableCancelButton(sectionId) {
        if (this.requestWaiter[sectionId - 1]) return true;
        return false;
      },

      disableSaveButton(sectionId) {
        switch (sectionId) {
          case 1:
            if (this.requestWaiter[sectionId - 1]) return true;


            let boolean =
              this.tempSellerInfo.IBAN === this.sellerInfo.IBAN &&
              this.tempSellerInfo.city === this.sellerInfo.city &&
              this.tempSellerInfo.town === this.sellerInfo.town &&
              this.tempSellerInfo.address === this.sellerInfo.address &&
              this.tempSellerInfo.identification_id === this.sellerInfo.identification_id &&
              (this.sellerInfo.gsm ? this.tempSellerInfo.gsm.replace(/ /g, "") === this.sellerInfo.gsm.replace(/ /g, "") : true) &&
              this.tempSellerInfo.firstname === this.sellerInfo.firstname &&
              this.tempSellerInfo.lastname === this.sellerInfo.lastname;


            if (this.tempSellerInfo.type !== 'bireysel') {
              boolean =
                this.tempSellerInfo.company_title === this.sellerInfo.company_title &&
                this.tempSellerInfo.tax_id === this.sellerInfo.tax_id &&
                this.tempSellerInfo.tax_office === this.sellerInfo.tax_office && boolean;
            }

            return boolean
        }
      },

      handleRequestWaiter(sectionId, boolean = false) {
        const requestWaiter = [...this.requestWaiter];
        requestWaiter[sectionId - 1] = boolean;
        this.requestWaiter = [...requestWaiter];
      },

      selectSellerType(type) {
        if (!this.user.seller.isRegistered && !this.requestWaiter[0] || this.isEditable) {
          this.tempSellerInfo.type = type
        }
      },

      iyziUpdate(sectionId) {
        let info = {
          firstname: this.tempSellerInfo.firstname,
          lastname: this.tempSellerInfo.lastname,
          address: this.tempSellerInfo.address,
          gsm: this.tempSellerInfo.gsm ? this.tempSellerInfo.gsm.replace(/ /g, "") : '',
          identification_id: this.tempSellerInfo.identification_id,
          IBAN: this.tempSellerInfo.IBAN,
          type: this.tempSellerInfo.type,
          city: this.tempSellerInfo.city,
          town: this.tempSellerInfo.town,
        };

        if (info.type !== "bireysel") {
          info.company_title = this.tempSellerInfo.company_title;
          info.tax_office = this.tempSellerInfo.tax_office;
          info.tax_id = this.tempSellerInfo.tax_id;
        }

        this.api.seller.iyziUpdate(info)
          .then(({data}) => {
            let result = data;
            if (result.success) {

              this.$store.state.user.seller.isRegistered = true;
              const seller = {...this.tempSellerInfo};
              seller.isRegestered = true;
              this.tempSellerInfo = {...seller};
              this.sellerInfo = {...seller};
              this.$toasted.global.infoToast({description: result.message});
              this.editableSectionId = null;
              this.getSeller();
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.handleRequestWaiter(sectionId);
          }).catch(err => {
          this.handleRequestWaiter(sectionId);
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      }


    },

    computed: {
      cardRange() {
        return range(0, this.cards.length, 3)
      }
    },

    watch: {},

    created() {
      this.EventBus.$on('newCardAdded', (payload) => {
        this.cards = [...payload]
      });
      if (this.user.seller.isSeller) this.initializeSeller();
      this.setInitialValues();

      this.EventBus.$off("updateAkbankEvent");
      this.$nextTick(() => {
        this.EventBus.$on('updateAkbankEvent',  ()=> {
          this.updateAkbankUser();
        });
      })
    }

  }

</script>

<style scoped lang="scss">

  .settings-container {
    display: flex;
    min-height: calc(100vh - 111px);
    width: 1140px !important;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
  }

  .content-container {
    box-sizing: border-box;
    padding-top: 40px;
    padding-left: 30px;
    padding-bottom: 340px;
    z-index: 1;
    background-color: #f4f5f7;
    width: 100%;
    min-height: calc(100vh - 111px);
  }

  .grey-background {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    min-height: calc(100vh);
    background-color: #f4f5f7;
  }

  .section {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .section-header-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 60px;

    .edit-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .edit-text {
        font-size: 14px;
        font-weight: 400;
        color: #8b95a1;
        margin-left: 5px;
      }
    }
  }

  .section-header {
    font-size: 24px;
    font-weight: 400;
    color: #4b4f52;

    span {
      font-weight: 600;
    }
  }

  .section-body {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #eaedf2;
    background-color: #fff;
    min-width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .separator {
    border-bottom: 1px solid #eaedf2;
    width: 100%;
  }

  .save-cancel-body {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .add-dash-button {
    box-sizing: border-box;
    border: 1px dashed #bfc8d2;
    padding: 0 10px 0 10px;
    border-radius: 4px;
    max-width: 100%;
    min-width: 66px;
    height: 30px;
    color: #8b95a1;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    background-color: #fff;

    &:hover {
      background-color: #fff !important;
    }
  }

  /*banka bilgileri*/

  .use-profile-info-text {
    font-size: 16px;
    color: #8b95a1;
    font-weight: 400;
    margin-right: 10px;
  }

  .bank-info {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .only-text-body {
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
  }

  .contact-item {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;

    .left {
      width: 25%;
      align-self: center;
    }

    .right {
      width: 75%;
    }
  }

  .contact-label {
    font-size: 16px;
    color: #8b95a1;
    font-weight: 400;
    margin: 0;
  }

  .contact-text {
    font-size: 16px;
    color: #2d3640;
    font-weight: 400;
  }

  .seller-type-body {
    box-sizing: border-box;
    padding: 20px 57px 20px 57px;
    border: 1px solid #eaedf2;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    margin-bottom: 10px;
  }

  .seller-type-text-body {
    display: flex;
    align-items: center;
  }

  .type-header {
    font-size: 16px;
    font-weight: 400;
    color: #8b95a1;
  }


  .hint-skill-icon /deep/ {
    width: 16px;
    height: 16px;
    margin-left: 5px;

    g {
      fill: #5e6b79;
    }
  }

  .type-radio-body {
    display: flex;
    align-items: center;
    margin-left: 50px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #fff;

    &:hover {
      background: none !important;
    }

    &:disabled {
      background: none !important;

      .type-radio-text {
        color: #bfc8d2;
      }

      .type-radio-body-button {
        border-color: #bfc8d2;
      }

    }
  }

  .type-radio-body-button {
    box-sizing: border-box;
    border: 1px solid #8b95a1;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    position: relative;
  }

  .selected-radio-mask {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }

  .type-radio-text {
    font-size: 16px;
    color: #2d3640;
    font-weight: 400;
    margin-left: 8px;
  }

  /*card info*/
  .card-info-section {
    max-width: 100%;
    width: 890px;
    padding-top: 75px;
    padding-bottom: 75px;

  }

  .no-card-text {
    font-size: 14px;
    color: #5e6b79;
    line-height: 26px;
    font-weight: 300;

    span {
      font-weight: 800;
      color: #2d3640;
    }
  }

  .swiper-button-prev {
    background-image: url(https://gcdn.bionluk.com/site/icon/c-left-swipe.png);
    background-size: cover;
    width: 40px;
    height: 40px;
    left: 0;
    opacity: 1;

    &:hover {
      background-image: url(https://gcdn.bionluk.com/site/icon/c-left-swipe-hover.png);
    }
  }

  .swiper-button-next {
    background-image: url(https://gcdn.bionluk.com/site/icon/c-right-swipe.png);
    background-size: cover;
    width: 40px;
    height: 40px;
    right: 0;
    opacity: 1;

    &:hover {
      background-image: url(https://gcdn.bionluk.com/site/icon/c-right-swipe-hover.png);
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: all !important;
  }


  .checkout-cards-container {
    padding-top: 56px;
    padding-bottom: 51px;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    position: relative;

  }

  .checkout-cards-inner {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    display: flex;
    padding-left: 115px;
    padding-right: 115px;
  }

  .cards {
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 180px;
    min-width: 180px;
    height: 190px;
    border-radius: 8px;
    border: 1px solid #bfc8d2;
    margin-left: 20px;
    margin-right: 20px;
    color: #8b95a1;

    &:hover {
      border: 1px solid #67cc64;
    }
  }


  .delete-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: -5px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
      .delete-card-img {
        opacity: 1;
      }

      .delete-card-text {
        color: #2d3640;
      }
    }
  }

  .delete-card-text {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.38px;
    margin-left: 5px;
    color: #8b95a1;
  }

  .delete-card-img {
    opacity: 0.5;
  }

  .forget-card-alert {
    cursor: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 5;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .forget-card-alert-button {
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 2px;
    width: 68px;
    height: 24px;
    text-align: center;
    letter-spacing: -0.38px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;

    &:hover {
      border: 1px solid #67cc64;
    }
  }

  .forget-card-alert-text {
    margin-bottom: 10px;
    font-size: 14px;
    color: #fff;
    letter-spacing: -0.44px;
  }

  .card-name {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .card-number {
    font-weight: 800;
    margin-top: 5px;
    text-align: center;
  }

  .delete-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: -5px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
      .delete-card-img {
        opacity: 1;
      }

      .delete-card-text {
        color: #2d3640;
      }
    }
  }

  .delete-card-text {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.38px;
    margin-left: 5px;
    color: #8b95a1;
  }

  .delete-card-img {
    opacity: 0.5;
  }

  .forget-card-alert {
    cursor: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 5;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .forget-card-alert-button {
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 2px;
    width: 68px;
    height: 24px;
    text-align: center;
    letter-spacing: -0.38px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;

    &:hover {
      border: 1px solid #67cc64;
    }
  }

  .forget-card-alert-text {
    margin-bottom: 10px;
    font-size: 14px;
    color: #fff;
    letter-spacing: -0.44px;
  }

  .add-icon /deep/ {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-bottom: -2px;

    path {
      fill: #8b95a1;
    }
  }


  .finance-info{
    width: 500px;
    height: 100px;
    border-radius: 10px;
    border: solid 1px rgba(242, 153, 51, 0.3);
    background-color: rgba(242, 153, 51, 0.2);
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;

    .disapproval-row{
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: white;
      .link{
        font-weight: 600;
        color: white;
        text-decoration: underline;
      }
    }

    &.for-seller {
      border: solid 1px rgba(90, 38, 164, 0.3);
      background-color: rgba(255, 36, 00, 1);
    }
    .for-seller-icon /deep/{
      width: 36px;
      height: 36px;
      path{
        fill: white;
      }
    }
    .for-buyer-icon /deep/{
      width: 36px;
      height: 36px;
      path{
        fill: #f29933;
      }
    }
    .delete-icon /deep/{
      width: 16px;
      height: 16px;
      path{
        fill: #2d3640;
      }
    }
    .title{
      font-size: 16px;
      font-weight: 500;
      color: #343d4a;
      padding-bottom: 4px;
    }
    .description{
      width: 408px;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.29;
      color: #4f596c;
    }
  }
</style>
